@font-face {
font-family: '__sofiaproRegular_f56f15';
src: url(/_next/static/media/f6e0178014af62b1-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__sofiaproRegular_Fallback_f56f15';src: local("Arial");ascent-override: 71.98%;descent-override: 23.99%;line-gap-override: 0.00%;size-adjust: 104.19%
}.__className_f56f15 {font-family: '__sofiaproRegular_f56f15', '__sofiaproRegular_Fallback_f56f15'
}.__variable_f56f15 {--font-sofiapro-regular-sans: '__sofiaproRegular_f56f15', '__sofiaproRegular_Fallback_f56f15'
}

@font-face {
font-family: '__sofiaproRegularItalics_c14141';
src: url(/_next/static/media/3c2c42dbb6c81722-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__sofiaproRegularItalics_Fallback_c14141';src: local("Arial");ascent-override: 71.98%;descent-override: 23.99%;line-gap-override: 0.00%;size-adjust: 104.19%
}.__className_c14141 {font-family: '__sofiaproRegularItalics_c14141', '__sofiaproRegularItalics_Fallback_c14141'
}.__variable_c14141 {--font-sofiapro-regular-italics-sans: '__sofiaproRegularItalics_c14141', '__sofiaproRegularItalics_Fallback_c14141'
}

@font-face {
font-family: '__sofiaproBold_7dfb96';
src: url(/_next/static/media/a94fd49072a38ecc-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__sofiaproBold_Fallback_7dfb96';src: local("Arial");ascent-override: 71.45%;descent-override: 23.82%;line-gap-override: 0.00%;size-adjust: 104.96%
}.__className_7dfb96 {font-family: '__sofiaproBold_7dfb96', '__sofiaproBold_Fallback_7dfb96'
}.__variable_7dfb96 {--font-sofiapro-bold-sans: '__sofiaproBold_7dfb96', '__sofiaproBold_Fallback_7dfb96'
}

@font-face {
font-family: '__sofiaproBoldItalics_583a5d';
src: url(/_next/static/media/70fe552e394e16dd-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__sofiaproBoldItalics_Fallback_583a5d';src: local("Arial");ascent-override: 71.45%;descent-override: 23.82%;line-gap-override: 0.00%;size-adjust: 104.96%
}.__className_583a5d {font-family: '__sofiaproBoldItalics_583a5d', '__sofiaproBoldItalics_Fallback_583a5d'
}.__variable_583a5d {--font-sofiapro-bold-italics-sans: '__sofiaproBoldItalics_583a5d', '__sofiaproBoldItalics_Fallback_583a5d'
}

